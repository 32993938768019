import { getProviderReferralInfo } from 'api/referral';
import { useCallback, useEffect, useState } from 'react';
import { IRefferalInfo } from 'types/referral';

const useGetProviderReferralInfo = (isKlarity?:boolean):[IRefferalInfo | undefined, boolean, string, () => void] => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IRefferalInfo>();
    const [error, setError] = useState('');
    const [fetched, setFetched] = useState(false);

    const fetch = useCallback(async () => {
        if (!isKlarity) {
            setLoading(true);

            try {
                const res = await getProviderReferralInfo();

                if (res && !res.error) {
                    setData(res.data?.data);
                } else {
                    setError(res?.error);
                }
            } catch (e) {
                console.error(e);
            }

            setFetched(true);
            setLoading(false);
        }
    }, [isKlarity]);

    useEffect(() => {
        if (!fetched && !isKlarity) {
            fetch();
        }
    }, [fetched, isKlarity, fetch]);

    return [data, loading, error, fetch];
};

export default useGetProviderReferralInfo;
