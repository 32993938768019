import React from 'react';
import s from './s.module.less';
import { Elements, CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Button } from 'antd';
import { STRIPE_PUBLIC_KEY } from 'constants/stripe';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const GetStripeCheckoutId = () => {
    const elements = useElements();
    const stripe = useStripe();
    const [checkoutId, setCheckoutId] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);

    const getStripeCheckoutId = async () => {
        setLoading(true);
        try {
            const cardElement = elements.getElement(CardNumberElement);
            const res = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
            });

            if (res.error) {
                console.error(res.error);
            } else {
                setCheckoutId(res.paymentMethod.id);
            }
        } catch (e) {
            console.error(e);
        }

        setLoading(false);
    };

    return (
        <div className={s.wrap}>
            <CardNumberElement className={`${s.input} ${s.inputWithoutBorder}`} />
            <CardExpiryElement className={s.input} />
            <CardCvcElement className={s.input} />
            <Button loading={loading} onClick={getStripeCheckoutId}>获取checkoutId</Button>
            <div className={s.text}>{checkoutId}</div>
        </div>
    );
};

const StripeCheckoutId = () => {
    return (
        <Elements stripe={stripePromise}>
            <GetStripeCheckoutId />
        </Elements>
    );
};

export default StripeCheckoutId;
